import type { MenuDataItem } from 'egenie-common';
import React from 'react';

export const routes: MenuDataItem[] = [
  {
    children: [
      {
        exact: true,
        path: '/',
        redirect: '/egenie-ts-gallery/index',
      },
      {
        path: '/egenie-ts-gallery/showroomManage/index',
        title: '展厅管理',
        exact: true,
        component: React.lazy(() => import('./pages/showroomManage/index')),
      },
      {
        path: '/egenie-ts-gallery/gallery',
        title: '展厅订单',
        exact: true,
        component: React.lazy(() => import('./pages/galleryOrder/index')),
      },
      {
        path: '/egenie-ts-gallery/chooseStyleOrderDetail',
        title: '订单详情',
        exact: true,
        component: React.lazy(() => import('./pages/galleryOrder/components/orderDetailModal/index')),
      },
      {
        path: '/egenie-ts-gallery/galleryReturnOrder',
        title: '展厅退货单',
        exact: true,
        component: React.lazy(() => import('./pages/galleryReturnOrder/index')),
      },
      {
        path: '/egenie-ts-gallery/galleryStatistics/index',
        title: '展厅数据概览',
        exact: true,
        component: React.lazy(() => import('./pages/galleryStatistics')),
      },
      {
        path: '/egenie-ts-gallery/receivingRecord/index',
        title: '到货记录',
        exact: true,
        component: React.lazy(() => import('./pages/receivingRecord/index')),
      },
      {
        path: '/egenie-ts-gallery/arrivalGoods/index',
        title: '扫码到货',
        exact: true,
        component: React.lazy(() => import('./pages/arrivalGoods/index')),
      },
      {
        path: '/egenie-ts-gallery/principalManage/index',
        title: '主理人管理',
        exact: true,
        component: React.lazy(() => import('./pages/principalManage/index')),
      },
      {
        path: '/egenie-ts-gallery/clothingAgentVendor',
        title: '合作供应商管理',
        exact: true,
        component: React.lazy(() => import('./pages/clothingAgentVendor/index')),
      },
    ],
  },
];
